<script>
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      store.dispatch('PostingNotifierStore/preselectFastInterpretationStaticData');
      if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
      }
      // Filling the selectors for pricing templates
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
      }
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    extends: PostInterpretationPrototype,
    computed: {
      mainLinesNote() {
        return this.$gettext('For ordering urgent and emergency assignments, use this order form. <br> You will receive an order confirmation as soon as the interpreter is assigned the assignment.');
      },
      mainLines() {
        return [{
          components: [{
            name: 'language-section',
            params: {
              langToText: this.$gettext('Language'),
              disabledFields: this.disabledImportantFields,
              useSelectLanguagePopup: true
            }
          }, {
            name: 'date-time-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'subject-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'warning-section',
            condition: true
          }]
        }, {
          components: [{
            name: 'assignment-type-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }];
      },
      additionalLines() {
        return [{
          components: [{
            name: 'alternative-language-section',
            condition: this.showAlternativeLanguageSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'extra-info-inner-section',
            params: {
              showExtraInfoNote: true,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'attachments-inner-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'participants-section',
            condition: this.showParticipantsSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }];
      },
      footerTextParams() {
        return {
          dateTimes: [{
            startTime: this.fullStartTime,
            finishTime: this.fullFinishTime
          }],
          languageId: this.languageTo
        };
      }
    }
  };
</script>
